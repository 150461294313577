import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { MenuController, PopoverController } from '@ionic/angular';

import { SelectCompanyPopoverComponent } from '../../legacy/select-company-popover/select-company-popover.component';
import { MenuItem } from '../menu-item';
import { BlinkIcon } from '../../ui-icon';
import { Router } from "@angular/router";
import { UiExternalLinkService } from '../ui-external-link/ui-external-link.service';
import { UiExternalLinkOptions } from '../ui-external-link/ui-external-link-options';
import { SelectProfilePopoverComponent } from '../../legacy/select-profile-popover/select-profile-popover.component';
import { List } from 'immutable';

@Component({
  selector: 'ui-main-menu',
  templateUrl: './ui-main-menu.component.html',
  styleUrls: ['./ui-main-menu.component.scss'],
  encapsulation: ViewEncapsulation.Emulated
})
export class UiMainMenuComponent {
  BlinkIcon = BlinkIcon;

  @Input() showUpdateButton: boolean;
  @Input() tabs: Array<MenuItem>;
  @Input() companies;
  @Input() selectedCompanyId: number;
  @Input() items: Array<MenuItem>;
  @Input() externalLinks: Array<UiExternalLinkOptions>;
  @Input() loginProfiles;
  @Input() activeProfile: any;

  @Output() companySelected = new EventEmitter<any>();
  @Output() loginProfileSelected = new EventEmitter<any>();
  @Output() customMenuItemClicked = new EventEmitter<MenuItem>();

  platform;

  get showCompanySelect() {
    return this.companies
      && this.companies.filter(x => x.Id !== null).length > 1
      && (this.loginProfiles === null
        || (this.loginProfiles
          && this.loginProfiles.length < 2)
      )
  }

  constructor(private popoverController: PopoverController,
              private menuController: MenuController,
              private uiExternalLinksService: UiExternalLinkService,
              private router: Router) {
    window['uiMenu'] = this;
  }

  get selectedCompanyName() {
    return this.companies?.find(c => c.Id === this.selectedCompanyId)?.Name;
  }

  async selectCompany(event) {
    const fakeEvent: any = {
      target: event.currentTarget
    }

    const companyPopover = await this.popoverController.create({
      component: SelectCompanyPopoverComponent,
      componentProps: {
        companies: this.companies,
        selectedCompany: this.selectedCompanyId
      },
      id: 'company-select',
      event: fakeEvent,
      showBackdrop: false,
    });
    companyPopover.onDidDismiss()
      .then(response => this.companySelected.emit(response));
    await companyPopover.present();
  }

  getVisibleItems(items: Array<MenuItem>): MenuItem[] {
    return items.filter(x => !x.hidden);
  }

  refresh() {
    window.location.reload();
  }

  navigateHome() {
    this.menuController.close().then(() =>
      this.router.navigate(['home']));
  }

  navigateToMenuPoint(event: MouseEvent, item: MenuItem) {
    event.stopPropagation();
    if (item.routerLink) {
      this.menuController.close().then(() =>
        this.router.navigate([item.routerLink]));
    } else if (item.customLink) {
      this.customMenuItemClicked.emit(item);
    } else {
      item.open = !item.open;
    }
  }

  async openExternalLink(externalLinkOptions: UiExternalLinkOptions) {
    await this.uiExternalLinksService.confirm(externalLinkOptions);
  }

  async selectProfile(event) {
    const fakeEvent: any = {
      target: event.currentTarget
    }

    const companyPopover = await this.popoverController.create({
      component: SelectProfilePopoverComponent,
      componentProps: {
        loginProfiles: this.loginProfiles,
        activeProfile: this.activeProfile
      },
      id: 'company-select',
      event: fakeEvent,
      showBackdrop: false,
    });
    companyPopover.onDidDismiss()
      .then(response => {
        if (response && response.data && response.role === 'loginProfileSelected') {
          this.loginProfileSelected.emit(response.data)
        }
      });
    await companyPopover.present();
  }
}
